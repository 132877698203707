import config from 'config';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
export const useBrowserComptabilityScript = () => {
    const delay = 5;
    let history = useHistory();
    const getElementsByClassName = (node, classname) => {
        var a = [];
        var re = new RegExp('(^| )' + classname + '( |$)');
        var els = node.getElementsByTagName('*');
        for (var i = 0, j = els.length; i < j; i++) if (re.test(els[i].className)) a.push(els[i]);
        return a;
    };

    useEffect(() => {
        let timer = setTimeout(() => {
            let root = getElementsByClassName(document.body, 'ant-design-pro ant-pro-basicLayout').length;
            let sideWidgets = getElementsByClassName(document.body, 'ant-col ant-col-24 ant-col-md-8').length;
            let map = getElementsByClassName(document.body, 'ant-col ant-col-24 ant-col-md-15').length;
            if (root + sideWidgets + map < 2){
                console.log("Your browser is not compatible")
                history.push(config.routes.browserIssues);
            } 
        }, delay * 1000);
        return () => {
            clearTimeout(timer);
        };
    }, [history]);

};
