import React from 'react';
import AntdIcon from '@ant-design/icons';

import Home from './Home';
import Chrome from './GoogleChrome';
import GreenCheck from './GreenCheck';
import Firefox from './MozrillaFirefox';
import Microsoft from './Microsoft';
import Elevator from './Elevator';
import Logout from './Logout';
import ChevronRight from './ChevronRight';
import DeviceOffline from './DeviceOffline';
import TechnicianOnSite from './TechnicianOnSIte';
import Phone from './Phone';
import NoIot from './NoIoT';
import Status from './Status';
import ChevronLeft from './ChevronLeft';
import Envelope from './Envelope';
import Email from './Email';
import Book from './Book';
import PhoneBold from './PhoneBold';
import CloseIcon from './Close';
import { Protector } from './Protector';
import CloseBoldIcon from './CloseBold';
import PowerOutage from './PowerOutage';
import NoTechnician from './NoTechnician';
import AlarmOutOfOrder from './AlarmOutOfOrder';
import Document from './Document';
import MapPin from './MapPin';
import { StatusColor } from '../../types';
import UnknownError from './UnknownError';
import ChevronRightBig from './ChevronRightBig';
import Download from './Download';
import ChevronDown from './ChevronDown';
import ChevronUp from './ChevronUp';
import Clock from './Clock';
import SortUp from './SortUp';
import SortDown from './SortDown';
import Checkmark from './Checkmark';
import CheckmarkBold from './CheckmarkBold';
import Info from './Info';
import { DragNDrop } from './DragNDrop';
import { DocumentSmall } from './DocumentSmall';
import { Trash } from './Trash';
import { QuotationCheck } from './QuotationCheck';
import { ContractCheck } from './ContractCheck';
import { ZuesPriceCheck } from './ZuesPriceCheck';
import { ZuesDeficiencyCheck } from './ZuesDeficiencyCheck';
import { OperatorObligations } from './OperatorObligations';
import { MaintenanceAnalysis } from './MaintenanceAnalysis';
import { ComingSoon } from './ComingSoon';
import { MaintenanceAnalysisCostSave } from './MaintenanceAnalysisCostSave';
import { MaintenanceAnalysisManagement } from './MaintenanceAnalysisManagement';
import { MaintenanceAnalysisThumbsUp } from './MaintenanceAnalysisThumbsUp';
import { OperatorObligationsDate } from './OperatorObligationsDate';
import { OperatorObligationsOverview } from './OperatorObligationsOverview';
import { OperatorObligationsTracking } from './OperatorObligationsTracking';
import { AddElevator } from './AddElevator';
import { ReportUpload } from './ReportUpload';
import { UploadExcel } from './UploadExcel';
import Search from './Search';
import { Cancel } from './Cancel';
import { Back } from './Back';
import { NoElevators } from './NoElevators';
import { NoActiveIncidents } from './NoActiveIncidents';
import { NoActiveIncident } from './NoActiveIncident';
import { Minus } from './Minus';
import { Reset } from './Reset';
import { Message } from './Message';
import AlertWarning from './AlertWarning';
import { Plus } from './Plus';
import ArrowDown from './ArrowDown';
import ArrowUp from './ArrowUp';

export enum Type {
    HOME = 'home',
    ARROW_UP = 'arrow_up',
    ARROW_DOWN = 'arrow_down',
    AlertWarning = 'alert-warning',
    ELEVATOR = 'elevator',
    BOOK = 'book',
    IOT = 'IOT',
    REPORT = 'report',
    DOCUMENT = 'document',
    DOCUMENT_SMALL = 'document-small',
    CONTRACT = 'contract',
    CLOSE = 'close',
    CLOSE_BOLD = 'close_bold',
    PROTECTOR = 'PROTECTOR',
    DOWNLOAD = 'download',
    DRAG_N_DROP = 'drag_n_drop',
    ENVELOPE = 'envelope',
    FINANCIALS = 'financials',
    SHIELD = 'shield',
    SEARCH = 'search',
    TASKS = 'tasks',
    SUPPORT = 'support',
    LOG_OUT = 'logout',
    CHECKMARK = 'checkmark',
    CHECKMARK_BOLD = 'checkmark_bold',
    CHEVRON_DOWN = 'chevron_down',
    CHEVRON_UP = 'chevron_up',
    CLOCK = 'clock',
    CHEVRON_RIGHT = 'chevron_right',
    CHEVRON_RIGHT_BIG = 'chevron_right_big',
    CHEVRON_LEFT = 'chevron_left',
    MAP_PIN = 'map_pin',
    DEVICE_OFFLINE = 'device_offline',
    INFO = 'info',
    EMAIL = 'email',
    Message = 'message',
    TECHNICIAN_ON_SITE = 'technician_on_site',
    TECHNICIAN_NOT_ON_SITE = 'technician_not_on_site',
    PHONE = 'phone',
    PHONE_BOLD = 'phone_bold',
    POWER_OUTAGE = 'power_outage',
    NO_IOT = 'no_iot',
    NO_TECHNICIAN = 'no_technician',
    SORT_UP = 'sort_up',
    SORT_DOWN = 'sort_down',
    ELEVATOR_STATUS = 'elevator_status',
    ELEVATOR_STATUS_SUCCESS = 'elevator_status_success',
    ELEVATOR_STATUS_WARNING = 'elevator_status_warning',
    ELEVATOR_STATUS_LOW_ALERT = 'elevator_status_low_alert',
    ELEVATOR_STATUS_HIGH_ALERT = 'elevator_status_high_alert',
    ELEVATOR_STATUS_INACTIVE = 'elevator_status_inactive',
    ALARM_OUT_OF_ORDER = 'alarm_out_of_order',
    UNKNOWN_ERROR = 'unknown_error',
    TRASH = 'trash',
    QUOTATION_CHECK = 'quotation_check',
    CONTRACT_CHECK = 'contract_check',
    ZUES_PRICE_CHECK = 'zues_price_check',
    ZUES_DEFICIENCY_CHECK = 'zues_deficiency_check',
    OPERATOR_OBLIGATION = 'operator_obligations',
    MAINTENANCE_ANALYSIS = 'maintenance_analysis',
    COMING_SOON = 'coming_soon',
    MAINTENANCE_ANALYSIS_COST_SAVE = 'maintenance_analysis_cost_save',
    MAINTENANCE_ANALYSIS_MANAGEMENT = 'maintenance_analysis_management',
    MAINTENANCE_ANALYSIS_THUMBS_UP = 'thumbs_up',
    OPERATOR_OBLIGATIONS_DATE = 'operator_obligations_date',
    OPERATOR_OBLIGATIONS_OVERVIEW = 'operator_obligations_overview',
    OPERATOR_OBLIGATIONS_TRACKING = 'operator_obligations_tracking',
    ADD_ELEVATOR = 'add_elevator',
    UPLOAD_EXCEL = 'upload_excel',
    REPORT_UPLOAD = 'report_upload',
    CANCEL = 'cancel',
    BACK = 'back',
    NO_ELEVATORS = 'no_elevators',
    NO_ACTIVE_INCIDENTS = 'no_active_incident',
    NO_ACTIVE_INCIDENT = 'no_incident',
    MINUS = 'minus',
    RESET = 'reset',
    PLUS = 'plus',
    CHROME = 'chrome',
    GreenCheck = 'GreenCheck',
    FIREFOX = 'firefox',
    MICROSOFT_EDGE = 'microsoft_edge',
}

type GetAntdIconProps<T> = T extends React.ForwardRefExoticComponent<infer X> ? X : never;

export interface IconProps extends GetAntdIconProps<typeof AntdIcon> {
    type: Type;
}

const getIcon = (type: Type) => {
    switch (type) {
        case Type.HOME:
            return Home;
        case Type.SEARCH:
            return Search;
        case Type.BOOK:
            return Book;
        case Type.ELEVATOR:
            return Elevator;
        case Type.AlertWarning:
            return AlertWarning;
        case Type.IOT:
            return null;
        case Type.REPORT:
            return null;
        case Type.DOCUMENT:
            return Document;
        case Type.ARROW_DOWN:
            return ArrowDown;
        case Type.ARROW_UP:
            return ArrowUp;
        case Type.DOCUMENT_SMALL:
            return DocumentSmall;
        case Type.DRAG_N_DROP:
            return DragNDrop;
        case Type.CONTRACT:
            return null;
        case Type.CLOSE:
            return CloseIcon;
        case Type.CLOSE_BOLD:
            return CloseBoldIcon;
        case Type.PROTECTOR:
            return Protector;
        case Type.ENVELOPE:
            return Envelope;
        case Type.FINANCIALS:
            return null;
        case Type.SHIELD:
            return null;
        case Type.TASKS:
            return null;
        case Type.SUPPORT:
            return Message;
        case Type.LOG_OUT:
            return Logout;
        case Type.EMAIL:
            return Email;
        case Type.CHECKMARK:
            return Checkmark;
        case Type.CHECKMARK_BOLD:
            return CheckmarkBold;
        case Type.CHEVRON_DOWN:
            return ChevronDown;
        case Type.CHEVRON_UP:
            return ChevronUp;
        case Type.CLOCK:
            return Clock;
        case Type.CHEVRON_RIGHT:
            return ChevronRight;
        case Type.CHEVRON_RIGHT_BIG:
            return ChevronRightBig;
        case Type.CHEVRON_LEFT:
            return ChevronLeft;
        case Type.DOWNLOAD:
            return Download;
        case Type.DEVICE_OFFLINE:
            return DeviceOffline;
        case Type.INFO:
            return Info;
        case Type.TECHNICIAN_ON_SITE:
            return TechnicianOnSite;
        case Type.TECHNICIAN_NOT_ON_SITE:
            return NoTechnician;
        case Type.PHONE:
            return Phone;
        case Type.PHONE_BOLD:
            return PhoneBold;
        case Type.POWER_OUTAGE:
            return PowerOutage;
        case Type.NO_IOT:
            return NoIot;
        case Type.ELEVATOR_STATUS:
            return Status;
        case Type.ELEVATOR_STATUS_SUCCESS:
            return () => <Status color={StatusColor.SUCCESS} />;
        case Type.ELEVATOR_STATUS_WARNING:
            return () => <Status color={StatusColor.WARNING} />;
        case Type.ELEVATOR_STATUS_LOW_ALERT:
            return () => <Status color={StatusColor.LOW_ALERT} />;
        case Type.ELEVATOR_STATUS_HIGH_ALERT:
            return () => <Status color={StatusColor.HIGH_ALERT} />;
        case Type.ELEVATOR_STATUS_INACTIVE:
            return () => <Status color={StatusColor.INACTIVE} />;
        case Type.ALARM_OUT_OF_ORDER:
            return AlarmOutOfOrder;
        case Type.UNKNOWN_ERROR:
            return UnknownError;
        case Type.Message:
            return Message;
        case Type.MAP_PIN:
            return MapPin;
        case Type.SORT_UP:
            return SortUp;
        case Type.SORT_DOWN:
            return SortDown;
        case Type.TRASH:
            return Trash;
        case Type.QUOTATION_CHECK:
            return QuotationCheck;
        case Type.CONTRACT_CHECK:
            return ContractCheck;
        case Type.ZUES_PRICE_CHECK:
            return ZuesPriceCheck;
        case Type.ZUES_DEFICIENCY_CHECK:
            return ZuesDeficiencyCheck;
        case Type.COMING_SOON:
            return ComingSoon;
        case Type.MAINTENANCE_ANALYSIS:
            return MaintenanceAnalysis;
        case Type.MAINTENANCE_ANALYSIS_COST_SAVE:
            return MaintenanceAnalysisCostSave;
        case Type.MAINTENANCE_ANALYSIS_MANAGEMENT:
            return MaintenanceAnalysisManagement;
        case Type.MAINTENANCE_ANALYSIS_THUMBS_UP:
            return MaintenanceAnalysisThumbsUp;
        case Type.OPERATOR_OBLIGATION:
            return OperatorObligations;
        case Type.OPERATOR_OBLIGATIONS_DATE:
            return OperatorObligationsDate;
        case Type.OPERATOR_OBLIGATIONS_OVERVIEW:
            return OperatorObligationsOverview;
        case Type.OPERATOR_OBLIGATIONS_TRACKING:
            return OperatorObligationsTracking;
        case Type.ADD_ELEVATOR:
            return AddElevator;
        case Type.REPORT_UPLOAD:
            return ReportUpload;
        case Type.UPLOAD_EXCEL:
            return UploadExcel;
        case Type.CANCEL:
            return Cancel;
        case Type.BACK:
            return Back;
        case Type.NO_ELEVATORS:
            return NoElevators;
        case Type.NO_ACTIVE_INCIDENTS:
            return NoActiveIncidents;
        case Type.NO_ACTIVE_INCIDENT:
            return NoActiveIncident;
        case Type.MINUS:
            return Minus;
        case Type.RESET:
            return Reset;
        case Type.PLUS:
            return Plus;
        case Type.FIREFOX:
            return Firefox;
        case Type.CHROME:
            return Chrome;
        case Type.MICROSOFT_EDGE:
            return Microsoft;
        case Type.GreenCheck:
            return GreenCheck;
    }
};

export const Icon = ({ type, ...props }: IconProps) => {
    const Icon = getIcon(type);

    if (!Icon) {
        return null;
    }

    return <AntdIcon component={Icon} {...props} />;
};
