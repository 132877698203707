/* eslint-disable camelcase */
import React from 'react';
const config = {
    api: {
        base: 'https://api-customer-stage.simplifa.de/api',
    },
    devTools: true,
    mapApiKey: 'AIzaSyBPgInUKq_VkoeU6o9H1LzYnzVxs-WJJ24',
    aws: {
        amplify: {
            Auth: {
                userPoolId: 'eu-central-1_I7KGAWQ9W',
                userPoolWebClientId: '1dhhj0laebp8tv6fcr6a1uicvi',
            },
            aws_appsync_graphqlEndpoint:
                'https://jgoi5v2rqbfanlb6zz36qdl5gy.appsync-api.eu-central-1.amazonaws.com/graphql',
            aws_appsync_region: 'eu-central-1',
            aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
        },
    },
    cookieBot: (
        <script
            id="Cookiebot"
            src="https://consent.cookiebot.com/uc.js"
            data-cbid="df719740-ae1a-40a3-9e6e-f23c2782b31b"
            data-blockingmode="auto"
            type="text/javascript"
        ></script>
    ),
    hotjar: {
        id: 3475969,
        version: 6,
    },
};

export default config;
