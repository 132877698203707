import { isEmpty } from 'lodash';

// To show all elevators in view on initial render
export const fitBounds = (map: google.maps.Map, markers: google.maps.Marker[]) => {
    if (isEmpty(markers)) {
        return;
    }

    const bounds = new google.maps.LatLngBounds();

    markers.forEach(marker => {
        bounds.extend(marker.getPosition());
    });

    map.fitBounds(bounds);
};
