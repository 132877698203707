// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "\n", "",{"version":3,"sources":[],"names":[],"mappings":"","file":"colors.less"}]);
// Exports
exports.locals = {
	"simplifa1": "#69b0ab",
	"simplifa2": "#284a6e",
	"white": "#ffffff",
	"black": "#000000",
	"backdrop": "rgba(0, 0, 0, 0.08)",
	"primary": "#ff5b5c",
	"selectedPrimary": "#ff5b5c",
	"selectedSecondary": "#fde6d9",
	"info": "#91d5ff",
	"onHover": "#ffd7d8",
	"bgPrimary": "#f1f3f5",
	"bgSecondary": "#ffffff",
	"outlineGrey": "#d9d9d9",
	"btnPrimaryHover": "#ff7f7f",
	"borderPrimaryHover": "#ff9f9f",
	"icon": "#a6a6a6",
	"iconActive": "#595959",
	"iconDisabled": "#a6a6a64d",
	"text": "#262626",
	"textSecondary": "#8f8f8f",
	"textGrey": "#5c5c5c",
	"statusSuccess": "#03be19",
	"bgStatusSuccess": "#f6ffed",
	"outlineStatusSuccess": "#b7eb8f",
	"statusHighAlert": "#ff5b5c",
	"bgStatusHighAlert": "#fff1f0",
	"outlineStatusHighAlert": "#ff9f9f",
	"statusLowAlert": "#ff7f7f",
	"statusOngoing": "#ff7f7f",
	"bgStatusLowAlert": "#ffe3e3",
	"outlineStatusLowAlert": "#ffc2c2",
	"statusWarning": "#ffd200",
	"statusInClarification": "#ffd200",
	"bgStatusWarning": "#fffbe6",
	"outlineStatusWarning": "#ffe58f",
	"statusInfo": "#009aff",
	"statusNew": "#197be6",
	"statusNotChecked": "#000",
	"bgStatusInfo": "#e6f7ff",
	"outlineStatusInfo": "#91d5ff",
	"bgStatusDefault": "#efefef",
	"outlineStatusDefault": "#666666",
	"statusInactive": "#a6a6a6",
	"statusDataCapture": "#197be6",
	"statusInProgress": "#ffd200",
	"statusDone": "#01be19",
	"overallStatusDescripitonColor": "#5c5c5c",
	"dividerColor": "#ebebeb",
	"borderColor": "#ebebeb",
	"lightBlue": "#66b5ff",
	"graphInfoColor": "#d8d8d8",
	"graphSecondaryBarColor": "#1a86e5",
	"inputPlaceholder": "#ddd",
	"uploadDraggerDisabled": "#f9fafb",
	"uploadRemove": "#e51a1a",
	"typographyListButtletColor": "#e6e6e6"
};
module.exports = exports;
